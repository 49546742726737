<template>
  <div :class="['time',active]">
    <appheader 
      :headline='$t("navigation.report")'
    ></appheader>
    <hint>{{$t("tour.time.hint")}}</hint>
    <timerPopup
        :visible="timerPopupActive"
        :setTimerPopupState='setTimerPopupState'
        :popupLabelValue='popupLabelValue'
        popupTextValue=''
        popupKmValue=''
        :curEntryType='curEntryType'>
    </timerPopup>
    <formWrap class="trackingButtonWrap" title="">
        <formButton :class='{active:this.active==="drive"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Fahrzeit.svg")' v-on:click.native='clickTimer("drive","Fahren")'>Fahren</formButton>
        <formButton :class='{active:this.active==="unload"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Entladen.svg")' v-on:click.native='clickTimer("unload","Entladen")'>Entladen</formButton>
        <formButton :class='{active:this.active==="load"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Beladen.svg")' v-on:click.native='clickTimer("load","Beladen")'>Beladen</formButton>
        <formButton :class='{active:this.active==="border"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Zoll.svg")' v-on:click.native='clickTimer("border","Grenzübertritt")'>Grenzübertritt</formButton>
        <formButton :class='{active:this.active==="repair"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Wartung.svg")' v-on:click.native='clickTimer("repair","Repar./Wartung")'>Repar./Wartung</formButton>
        <formButton :class='{active:this.active==="pause"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Pause.svg")' v-on:click.native='clickTimer("pause","Pause")'>Pause</formButton>
        <formButton :class='{active:this.active==="stand"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Sonst_Stehzeit.svg")' v-on:click.native='clickTimer("stand","sonst.Stehzeit")'>sonst.Stehzeit</formButton>
        <!--<formButton :class='{active:this.active==="stop"}' class="timerButton" :imgSrc='getImgUrl("view-nachtrag/3_Bericht_Sonst_Stehzeit.svg")' v-on:click.native='clickTimer("stop","Stop")'>Stop</formButton>-->
        <div class="button-container">
        <routerButton destination='./' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
        <formButton
          :imgSrc='getImgUrl("Nav_check.svg")'
          v-on:click.native='clickCheck()'>
        </formButton>
      </div>
    </formWrap>
    <formWrap :title='$t("tour.time.records")'>
        <div class="plate">{{$t("tour.time.vehicle")}}: {{licensePlate}}</div><div class="plate">{{$t("tour.time.trailer")}}: {{licensePlateTrailer}}</div>
        <timeEntryList></timeEntryList>
    </formWrap>
    <formWrap :title='$t("tour.time.times")'>
        <timeLable :active='this.active=="drive" ? true : false' class="timerLabel" label='Fahren' :secondsValue='time("drive")'/>
        <timeLable :active='this.active=="unload" ? true : false' class="timerLabel" label='Entladen' :secondsValue='time("unload")'/>
        <timeLable :active='this.active=="load" ? true : false' class="timerLabel" label='Beladen' :secondsValue='time("load")'/>
        <timeLable :active='this.active=="border" ? true : false' class="timerLabel" label='Grenzübertritt' :secondsValue='time("border")'/>
        <timeLable :active='this.active=="repair" ? true : false' class="timerLabel" label='Rep./Wartung' :secondsValue='time("repair")'/>
        <timeLable :active='this.active=="pause" ? true : false' class="timerLabel" label='Pause' :secondsValue='time("pause")'/>
        <timeLable :active='this.active=="stand" ? true : false' class="timerLabel" label='sonst.Stehzeit' :secondsValue='time("stand")'/>
    </formWrap>
    <appfooter></appfooter>
  </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
import TimewithLable from "@/components/TimewithLable.vue";
import TimeEntryList from "@/components/TimeEntryList.vue";
import TimerPopup from "@/components/Popups/TimerPopup.vue"
import { mapGetters } from 'vuex'

export default {
    data(){
        return{
            timerPopupActive:false,
            curEntryType:"initial",
            popupLabelValue:'',
        }
    },
    components: {
        'formButton':Button,
        'timeLable':TimewithLable,
        'timeEntryList':TimeEntryList,
        'timerPopup' : TimerPopup,
    },
    computed: {
        active:{
            // getter
            get: function () {
                return this.$store.state.local.nachtragTimer.currentlyTracked
            },
        },
        licensePlate:function(){
            return this.$store.getters.currVehiclePlate;   
        },
        licensePlateTrailer:function(){
            return this.$store.getters.currTrailerPlate;   
        },
        ...mapGetters([
            'getNextRoute',
        ])
    },
    methods:{
        time(type){
            return this.$store.getters.getTime(type);
        },
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        clickCheck(){
            if(this.active !== "stop"){
                console.log("if this.active: ",this.active);
                this.clickTimer("stop","Zeiterfassung stoppen?");
            }else{
                console.log("else this.active: ",this.active);
                this.$router.push(this.getNextRoute("time"))
            }
        },
        setTimerPopupState(state){
            this.timerPopupActive = state;
        },
        clickTimer(type,label){
            this.timerPopupActive = true;
            console.log("type: ",type)
            this.curEntryType = type;
            this.popupLabelValue = label;
            console.log("active: ",this.active)
        },
        setTimer(){
            //maybe check if new km value is > as the old one (LKW should not drive backwards)
            if(this.curEntryType !== null && this.popupKmValue !== null && this.popupKmValue !== ""){
                this.$store.commit("trackTime",{type:this.curEntryType, text:this.popupTextValue, km:this.popupKmValue});
                this.popupActive = false;
                this.warning=false;
                if(this.curEntryType === "stop"){
                    //TODO: timeout nessesary because router guard loads from cache and trackTime mutation is canceled out by it... check why this is the case
                    setTimeout(() => {this.$router.push(this.getNextRoute("time"))}, 10);
                }
            }else{
                this.warning=true;
            }
        },
    },
}

</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.time{
    background-color:$bgColorLight;
    min-height: 100vh;
}
.active.timerButton{
  background-color:$bgColorHighlighted;
}
.trackingButtonWrap > .button-wrap{
  margin: 10px auto;
  padding: 15px 15px;
  width:90%;
  text-transform: uppercase;
  font-size:1.3em;
  line-height: 1.5em;
  color:white;
  text-align:left;
  img{
    float: right;
  }
}
.button-container{
    width:100%;
    display:flex;
    justify-content: space-between;
}
.controllsWrap{
  width:100%;
  display:flex;
  justify-content: space-between;
}

.plate{
        width: 100%;
        text-align: left;
        padding: 5px;
        font-weight: bold;
    }
.warning{
    color:$bgColorHighlighted;
}
</style>
