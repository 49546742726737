<template>
    <div v-if='active' class="overlay">
        <div class="optionsPopup-wrap">
            <!--<div class="options-wrap" v-for="(option,index) in options" :key="index">
                <optionField :optionKey='index' :option='option' :field='field'></optionField> 
            </div>-->
            <div class="title">{{field.label}}</div>
            <div class="options-wrap">
                <optionField :optionKey='this.optionIndex' :option='this.option' :field='field'></optionField> 
            </div>
            <div class="buttons-wrap">
                <formButton v-if='this.declineMsg !== undefined' class="decline" :buttonText='declineMsg' v-on:click.native="decline()"></formButton>
                <formButton v-if='this.confirmMsg !== undefined' class="check" :buttonText='confirmMsg' v-on:click.native="confirm()"></formButton>
                <formButton v-else class="check" v-on:click.native="confirm()" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
import OptionField from "@/components/FormFields/OptionField.vue";

export default {
    components:{
        'formButton': Button,
        'optionField': OptionField,
    },
    props:{
        confirmMsg:String,
        declineMsg:String,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        //bring set value to parent component - decoupling
        confirm(){
            this.$store.commit("setOptionsPopup");
        },
        decline(){
            
        },
    },
    computed:{
        active: function(){
            return this.$store.state.status.optionsPopup.active;
        },
        optionIndex:function(){
            return this.$store.state.status.optionsPopup.option;
        },
        option: function(){
            //console.log("this.field.fieldId: ",this.field.fieldId)
            //console.log("this.field.formId: ",this.field.formId)
            //console.log("optionIndex: ",this.optionIndex)
            if(this.$store.state.local.checkResultsNew[this.field.formId] !== undefined){
                if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId] !== undefined){
                    //console.log("this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId]: ",this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId])
                    //console.log("this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId][this.optionIndex]: ",this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options[this.optionIndex])
                    return this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options[this.optionIndex];
                }
            }
            return {};
        },
        field: function(){
            return this.$store.state.status.optionsPopup.field;
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 1000;
    background-color: $bgColorMedTransparent;
    .optionsPopup-wrap{
        padding:20px;
        position: relative;
        width: 85vw;
        margin: auto;
        top:10%;
        background-color: $bgColorLightLight;
        color:$fontColorMed;
        border-radius: 8px;
        textarea{
            width: 100%;
            height:100px;
            font-size: 3rem;
            background: transparent;
            border: none;
            padding:5px;
            font-size: 1.2rem;
            resize:none;
            outline: none;
        }
        .buttons-wrap{
            display:flex;
            justify-content: flex-end;
            padding-top:10px;
        }
    }
}
.buttons-wrap .button-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}


</style>