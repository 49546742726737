<!-- shows the details of a specific form of a tour e.g. endcontroll -->
<template>
  <div class="docuDetail">
    <appheader 
      :headline='getValueForCurLanguage(detailData.form.form_title).value'
    ></appheader>
    <div class="form-fields">
        <div
          v-for="field of tree"
          :key="field.form_field_id"
          class="form-field"
        >
          <form-field-group-view 
            v-if="field.field_type.type === 'group'"
            :field="field" 
            :formId='field.form_field_id'
            :submissionId="$route.params.id"/>
          <form-field-view
            v-else-if='field.skip !== true'
            :class="'level0'"
            :field="field"
            :formId='field.form_field_id'
            :submissionId="$route.params.id"
          />
        </div>
        <slot></slot>
    </div>
    <div class="button-container">
        <routerButton destination='/docu' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
  </div>
 
</template>

<script>
import FormFieldGroupView from "@/components/FormFieldsView/FormFieldGroupRecView.vue";
import FormFieldView from "@/components/FormFieldsView/FormFieldView.vue";
import { mapGetters } from 'vuex'
export default {
  data(){
      return{
      }
  },
  components:{
    'form-field-group-view':FormFieldGroupView,
    'form-field-view':FormFieldView,
  },
  computed:{
    detailData: function(){
        console.log("this.$route.params.id: ",this.$route.params.id);
        return this.$store.state.local.documentation.tourDetails.submissions[this.$route.params.id];
    },
    tree() {
        console.log("this.detailData: ",this.detailData);
        return this.makeTree(this.detailData.form_fields,'form_field_id', 'parent_id', 'form_fields');
    },
    ...mapGetters([
        'getValueForCurLanguage',
    ]),
  },
  methods:{
        getImgUrl(img) {
        return require('@/assets/'+img);
        },
        makeTree(array, idProp, parentProp, childProp) {
            console.log("array: ",array);
            const items = {}; // collect items here
            // add each items so we can look them up later
            const children = {};
            array.forEach((field) => {
                items[field[idProp]] = {...field,};
                if (field.field_type?.type === 'group' || field.type === 'group') {
                    items[field[idProp]][childProp] = [];
                }
                if (!children[field[parentProp]]) children[field[parentProp]] = [];
                    children[field[parentProp]].push(field);
            });

            const tree = [];
            array.forEach((field) => {
                // add each item with a parentId to the correct parent
                if (field[parentProp]) {
                    if (items[field[parentProp]]) {
                        items[field[parentProp]][childProp].push(items[field[idProp]]);
                    }
                } else {
                    tree.push(items[field[idProp]]); // if no parentId add it to the root
                }
            });

            const calcLevel = (parent, level) => {
                const items = children[parent] || [];
                return items.map((item) => {
                const list = calcLevel(item[idProp], level + 1);
                const cur = { level };
                cur[childProp] = list;
                return Object.assign({}, item, cur);
                });
            };

            return calcLevel(0, 0);
        },
    },
};

</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.button-container{
    width:90%;
    margin:auto;
    margin-bottom:35px;
}

</style>
