<template>
<div v-if='visible' class="overlay">
        <div class="timerPopup-wrap">
            <div class="title">{{popupLabelValue}}</div>
            <div class="sepperator"></div>
            <div class="timer-wrap">
                <div v-if='isSupported("timeCountry")' class="country">Land:</div>
                <div v-if='loading'>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="country-res">
                    {{country}}
                </div>
                <textarea
                    class="textarea"
                    type="text"
                    :placeholder='isSupported("timeCountry") ? $t("tour.time.popupMsg") : $t("tour.time.popupMsgNoCountry")'
                    v-model='popupTextValue'/>
                <label v-if='isSupported("timeMileage")' for="km" class ="km">
                    {{$t("tour.time.mileage")}}:
                    <input
                        class="number"
                        type="number"
                        id="km"
                        min="0"
                        placeholder="Kilometer"
                        v-model='popupKmValue'/>
                </label>
            </div>
            <div class="buttons-wrap">
                <formButton class="decline" v-on:click.native="setTimerPopupState(false)" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
                <formButton class="check" v-on:click.native="setTimer()" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
            </div>
            <span v-if='warning !== undefined && warning !== null' class="warning">{{warning}}</span>
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
import { mapGetters } from 'vuex'
//var geocoder = require('local-reverse-geocoder');
//import geocoder from 'local-reverse-geocoder';
//var codegrid = require('@/assets/externalJS/reverseGeocoding codegrid/codegrid.js');

export default {
    data(){
        return{
            warning:undefined,
            popupTextValue:'',
            popupKmValue:'',
            geoService:true,
            geoLocation:{},
            grid:{},
            loading:false,
        }
    },
    props:{
        popupLabelValue:String,
        setTimerPopupState:Function,
        curEntryType:String,
        visible:Boolean,
    },
    components: {
        'formButton':Button,
    },
    computed: {
        active:{
            // getter
            get: function () {
                return this.$store.state.local.nachtragTimer.currentlyTracked;
            },
        },
        country:function(){
            return this.$store.state.local.nachtragTimer.country;
        },
        city:function(){
            return this.$store.state.local.nachtragTimer.city;
        },
         ...mapGetters([
            'getNextRoute',
        ])
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        setTimer(){
            //maybe check if new km value is > as the old one (LKW should not drive backwards)
            if(this.isSupported("timeMileage") && this.popupKmValue == ''){
                this.warning=this.$t("tour.time.enterMileage");
            }else{
                if(this.curEntryType !== null){
                    var text = "";
                    if(this.country != undefined && this.country !== "" && this.isSupported("timeCountry")){
                        text = this.country + " " +this.popupTextValue;
                    }else{
                        text = this.popupTextValue;
                    }
                    if(text != undefined && text != ""){
                        this.$store.commit("trackTime",{type:this.curEntryType, text:text, km:this.popupKmValue, geoLocation:this.geoLocation});
                        //this.popupActive = false;
                        this.setTimerPopupState(false);
                        this.warning=null;
                        if(this.curEntryType === "stop"){
                            //TODO: timeout nessesary because router guard loads from cache and trackTime mutaion is canceled out by it... check why this is the case
                            setTimeout(() => {this.$router.push(this.getNextRoute('time'))}, 10);
                        }
                    }else{
                        this.warning=this.$t("tour.time.enterCountry");
                    }
                }
            }
        },
        cloneAsObject(obj) {
            if (obj === null || !(obj instanceof Object)) {
                return obj;
            }
            var temp = (obj instanceof Array) ? [] : {};
            // ReSharper disable once MissingHasOwnPropertyInForeach
            for (var key in obj) {
                temp[key] = this.cloneAsObject(obj[key]);
            }
            return temp;
        },
        isSupported(feature){
            return this.$store.getters.isFeatureSupportet(feature);
        },
    },
    watch: { 
        visible: function(newVal) { // watch it
            this.loading = true;
            if(newVal == true){
                //console.log("visible changed from false to true ");
                if(!("geolocation" in navigator)) {
                    this.$store.dispatch('setStatusMsg',{values:{text:this.$t("messages.loadingLocationDataFailed"), type:'warning'},time:3000});
                    this.geoService = false;
                }else{
                        //this.$store.dispatch('setStatusMsg',{values:{text:this.$t("messages.notAllrequiresFieldsSet"), type:'neutral'},time:3000});
                        // get position
                        navigator.geolocation.getCurrentPosition(pos => {
                        //need this.cloneAsObject because pos can't be stringified directly. the properties are on the prototype
                        var raw = this.cloneAsObject(pos);
                        this.geoLocation = JSON.parse(JSON.stringify(raw));
                        //console.log("this.geoLocation: ",this.geoLocation);
                        //console.log("this.geolocation: ",this.geoLocation);
                        
                        this.$store.dispatch("reverseGeoLookup",this.geoLocation).then(()=>{
                            this.loading = false;
                        });

                    }, error => {
                        this.geoService = false;
                        //TODO show translated error Messages in users language
                        switch(error.code) {
                            case error.PERMISSION_DENIED:
                            //x.innerHTML = "User denied the request for Geolocation."
                            this.$store.dispatch('setStatusMsg',{values:{text:"1"+error.message, type:'error'},time:3000});
                            break;
                            case error.POSITION_UNAVAILABLE:
                            //x.innerHTML = "Location information is unavailable."
                            this.$store.dispatch('setStatusMsg',{values:{text:"2"+error.message, type:'error'},time:3000});
                            break;
                            case error.TIMEOUT:
                            //x.innerHTML = "The request to get user location timed out."
                            this.$store.dispatch('setStatusMsg',{values:{text:"3"+error.message, type:'error'},time:3000});
                            break;
                            case error.UNKNOWN_ERROR:
                            this.$store.dispatch('setStatusMsg',{values:{text:"3"+error.message, type:'error'},time:3000});
                            //x.innerHTML = "An unknown error occurred."
                            break;
                        }
                    }, {maximumAge:10000, timeout:5000, enableHighAccuracy: true})
                }
            }else{
                this.$store.commit('unSetAdress');
            }
        }
    },
}

</script>

<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.time{
    background-color:$bgColorLight;
    min-height: 100vh;
}
.active.timerButton{
  background-color:$bgColorHighlighted;
}
.trackingButtonWrap > .button-wrap{
  margin: 10px auto;
  padding: 15px 15px;
  width:90%;
  text-transform: uppercase;
  font-size:1.3em;
  line-height: 1.5em;
  color:white;
  text-align:left;
  img{
    float: right;
  }
}
.button-container{
    width:100%;
    display:flex;
    justify-content: space-between;
}
.controllsWrap{
  width:100%;
  display:flex;
  justify-content: space-between;
}
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 1000;
    background-color: $bgColorMedTransparent;
    .timerPopup-wrap{
        padding:20px;
        position: relative;
        width: 85vw;
        margin: auto;
        top:30%;
        background-color: $bgColorLightLight;
        color:$fontColorMed;
        border-radius: 8px;
        .title{
            font-size: 1.2rem;
            margin-bottom:5px;
        }
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 80%;
            margin:auto;
        }
        .country{
            margin-top:5px;
            padding-right:5px;
            width: 50%;
            float: left;
            text-align: right;
        }
        .country-res{
            margin-top:5px;
            padding-left:5px;
            text-align: left;
            width:50%;
            float:left;
        }
        .timer-wrap{
            display: block;
            position: relative;
        }
        textarea{
            width: 100%;
            height:100px;
            font-size: 3rem;
            background: transparent;
            border: none;
            padding:10px 5px;
            font-size: 1rem;
            resize:none;
            outline: none;
        }
        .km{
            /*display: block;
            width: 100%;
            text-align: left;*/
            width:100%;
            justify-content: space-between;
            line-height: 25px;
            display: flex;
            overflow:hidden;
            .number{
                max-width: 150px;
            }
        }
        .buttons-wrap{
            display:flex;
            justify-content: space-between;
            padding-top:10px;
        }
    }
}
.plate{
        width: 100%;
        text-align: left;
        padding: 5px;
        font-weight: bold;
    }
.warning{
    color:$fontColorHighlighted;
    margin-top: 20px;
    display: block;
}


/** CSS loader **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  float:left
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  margin-left:5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $fontColorDark;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>
