<template>
    <div class="nav-icon-wrap">
        <img class="nav-icon" :src='imgSrc'/>
        <div :class="['status',status]"></div>
        <span class="text">{{text}}</span>
    </div>
</template>

<script>

export default {
    props:{
        imgSrc:String,
        text:String,
        status:String,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.nav-icon-wrap{
    position: relative;;
    display:inline-flex;
    padding: 10px;
    background-color:$bgColorLight;
    flex: 1 1 40%;
    margin: 2%;
    max-width:170px;
    min-width: 135px;
    max-height:170px;
    min-height: 135px;
    cursor: pointer;
    img{
        width: 100%;
    }
    .text{
        position: absolute;
        width: 100%;
        text-align: center;
        bottom:15px;
        left:0;
        color:$fontColorDark;
    }
    .status{
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        top:20px;
        right:20px;
        background-size: contain;
        &.open{
            background-color: white;
            background-image:url("../assets/view-check/Abfahrk_status_offen.svg");
        }
        &.partial, &.failed,  &.ok{
            background-color: $trafficLightColorYellow;
            background-image:url("../assets/view-check/Abfahrk_status_leichterMangel.svg");
        }
        &.okSent{
            background-color: $trafficLightColorGreen;
            background-image:url("../assets/view-check/Abfahrk_status_erledigt.svg");
        }
        &.failedSent{
            background-color: $trafficLightColorRed;
            background-image:url("../assets/view-check/Abfahrk_status_schwererMangel.svg");
        }
        &.sendError{
            background-color: $trafficLightColorRed;
            background-image:url("../assets/view-check/Abfahrk_status_schwererMangel.svg");
        }
    }
}
</style>