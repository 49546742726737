<template>
  <div class="checklist">
    <appheader
      :headline='$t("navigation.checklist")'
    ></appheader>
    <MyForm 
      :formId='this.$store.state.local.curCheck.checkListId[0].formId' 
      :formBaseId='this.$store.state.local.curCheck.checkListId[0].baseId'
      :vehicleID='this.$store.state.local.curCheck.chosenTruck'
      :additionalForm='this.$store.state.local.curCheck.defectsId.baseId' 
      :readyToSend='formReadyToSend'
      :nextRoute='getNextRoute("checklist")'>
    </MyForm>
    <appfooter></appfooter>
  </div>
</template>

<script>
import MyForm from '@/components/Form.vue';
import { mapGetters } from 'vuex'

export default {
  components: {
    'MyForm': MyForm,
  },
  computed:{
    formReadyToSend: function(){
      console.log("this.$store.state.local.curCheck.checkListId[0].formId: ",this.$store.state.local.curCheck.checkListId[0].formId)
      var status = this.$store.getters.getFormFieldStatus(this.$store.state.local.curCheck.checkListId[0].formId);
      if(status == "ok" || status == "failed"){
        return true;
      }else{
        return false;
      }
    },
    ...mapGetters([
        'getNextRoute',
    ])
  }
};
</script>

<style lang="scss">
</style>