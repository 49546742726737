<!-- shows a list of documentation entries e.g. tours -->
<template>
    <div class="docu-entry-list">
        <div v-for='(entry,index) in getEntries()' :key="index" class="docu-entries-wrap">
            <docuentry :startTime='convertAPIDateTimeToString(entry.date_start)' :type='entry.license_plate' :page='page' :tourId='entry.tour_id' :index="index"></docuentry>
        </div>
        <div class="nav-wrap">
            <div class="prev-page-wrap">
                <div 
                    class="prev-page" 
                    v-if='this.page > 1' 
                    v-on:click='prevPage()'>
                    &lt;
                </div>
            </div>
            <div class="page-wrap">
                <div>
                    {{page}} / {{this.$store.state.local.documentation.pageCount}}
                </div>
            </div>
            <div class="next-page-wrap">
                <div 
                    class="next-page" 
                    v-if='this.$store.state.local.documentation.pageCount >= this.page +1'
                    v-on:click='nextPage()'>
                    &gt;
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DocuEntry from "@/components/DocuEntry.vue";
import dateFunctions from '@/mixins/dateFunctions'

export default {
    props:{
        page:Number,
    },
    methods: {
        getEntries:function(){
            var entries = this.$store.getters.getDocuEntries(this.page);
            console.log("entries: ",entries);
            return entries;
        },
        prevPage:function(){
            if(this.page > 1){
                this.$store.dispatch('getTourList',this.page -1);
                this.$store.commit('set_active_doc_page',this.page -1)
            }
        },
        nextPage:function(){
            //console.log("page: ",this.page);
            //console.log("pagecount: ",this.$store.state.local.documentation.pageCount);
            if(this.page < this.$store.state.local.documentation.pageCount){
                //console.log("nextpage in if");
                this.$store.dispatch('getTourList',this.page +1);
                this.$store.commit('set_active_doc_page',this.page +1)
            }
        },
    },
    components: {
        'docuentry':DocuEntry
    },
    beforeMount(){
        this.$store.dispatch('getTourList',this.page);
    },
    mixins: [dateFunctions],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .nav-wrap{
        margin:30px 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    .prev-page-wrap{
        flex: 1 0 33.33%;
        .prev-page{
            width: 100%;
            cursor: pointer;
        }
    }
    .next-page-wrap{
        flex: 1 0 33.33%;
        .next-page{
            width:100%;
            cursor: pointer;
        }
    }
</style>