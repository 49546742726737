<template>
  <div class="form" ref="form">
    <hint v-if="this.getChecklist !== undefined">{{getValueForCurLanguage(this.getChecklist.form_description).value}}</hint>
    <confirmpopup 
        :active='this.confirmActive' 
        :msg='this.$t("tour.defects.defectsExist")'
        :confirmMsg='this.$t("popups.noGoOn")' 
        :declineMsg='this.$t("popups.yes")'  
        :confirmed='this.confirm' 
        :declined='this.decline'>
    </confirmpopup>
    <options-popup></options-popup>
    <field-lock-popup></field-lock-popup>
    <div class="form-wrap">
      <text-field v-if="this.getChecklist !== undefined && false"
        fix
        :value='this.getChecklist.date_created'
        label='Startzeitpukt'
      ></text-field>
      <div class="form-fields">
        <div
          v-for="field of tree"
          :key="field.form_field_id"
          class="form-field"
        >
          <form-field-group v-if="field.field_type.type === 'group'" :field="field" :formId='formId'/>
          <form-field
            v-else-if='field.skip !== true'
            :field="field"
            :formId='formId'
          />
        </div>
        <slot></slot>
      </div>
      <div class="button-container">
        <routerButton :class='{"busy":formBusy}' class="backButton" destination='./' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
        <div v-if='formBusy'>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
        <formButton 
            class="confirmButton" 
            :class='{"disabled":!readyToSend, "busy":formBusy}'
            :imgSrc='getImgUrl("Nav_check.svg")' 
            v-on:click.native='confirmForm'>
        </formButton>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
//import TextFieldWithLable from "@/components/FormFields/TextFieldWithLable.vue";
import TextFieldWithLable from "@/components/FormFields/TextAreaWithLable.vue";
import FormFieldGroup from "@/components/FormFieldGroupRec.vue";
import FormField from "@/components/FormField.vue";
import OptionsPopup from "@/components/Popups/OptionsPopup.vue";
import FieldLockPopup from "@/components/Popups/FieldLockPopup.vue";
import { mapGetters } from 'vuex'
//import { mapGetters } from 'vuex'

export default {
  data(){
        return{
            confirmActive: false,
            formBusy: false,
        }
  },
  components: {
    'text-field':TextFieldWithLable,
    'form-field-group':FormFieldGroup,
    'form-field':FormField,
    'options-popup':OptionsPopup,
    'field-lock-popup':FieldLockPopup,
  },
  props:{
    formId:Number,
    formBaseId: Number,
    vehicleID: Number,
    additionalForm: Number,
    readyToSend: Boolean,
    nextRoute: String,
  },
  methods:{
    getImgUrl(img) {
      return require('@/assets/'+img);
    },
    getGroupTitle: function(group){
      return this.$store.getters.getFieldgroupByGroupId(group).title;
    },
    /*getFieldsByGroupId: function(group){
      return this.$store.getters.getFieldsByGroupId(group);
    },*/
    makeTree(array, idProp, parentProp, childProp) {
        const items = {}; // collect items here
        // add each items so we can look them up later
        const children = {};
        array.forEach((field) => {
            items[field[idProp]] = {
            ...field,
            };
            if (field.field_type?.type === 'group' || field.type === 'group') {
                items[field[idProp]][childProp] = [];
            }
            if (!children[field[parentProp]]) children[field[parentProp]] = [];
                children[field[parentProp]].push(field);
        });

        const tree = [];
        array.forEach((field) => {
            // add each item with a parentId to the correct parent
            if (field[parentProp]) {
                if (items[field[parentProp]]) {
                    items[field[parentProp]][childProp].push(items[field[idProp]]);
                }
            } else {
                tree.push(items[field[idProp]]); // if no parentId add it to the root
            }
        });

        const calcLevel = (parent, level) => {
            const items = children[parent] || [];
            return items.map((item) => {
                const list = calcLevel(item[idProp], level + 1);
                const cur = { level };
                cur[childProp] = list;
                return Object.assign({}, item, cur);
            });
        };

        return calcLevel(0, 0);
    },
    decline:function(){
        console.log("DECLINE");
        this.confirmActive = false;
        this.$router.push(this.nextRoute)
    },
    confirm: function(){
        this.$store.commit('setFormStatusFields',{formId:this.$store.state.local.curCheck.defectsId.formId,value:'ok'});
        this.$store.commit('setFormStatusSend',{formId:this.$store.state.local.curCheck.defectsId.formId,value:'sent'});
        //this.$store.dispatch('setFormFieldValues',this.$store.state.local.curCheck.defectsId.formId)
        this.confirmActive = false;
        setTimeout(()=>this.$router.push(this.getNextRoute('defects')));
    },
    confirmForm(){
        this.formBusy = true;
        if(this.readyToSend){
            this.$store.dispatch('setFormFieldValues',this.formId).then(()=>{
                if(this.formId === this.$store.state.local.curCheck.checkListId[0].formId && this.$store.state.local.curCheck.defectsId.baseId > 0){
                    this.confirmActive= true;
                    this.formBusy = false;
                }else{
                    setTimeout(()=>this.$router.push(this.nextRoute),10);
                }
            }).catch((error) =>{
                console.log(error);
                this.formBusy = false;
            })
        }else{
          if(this.formBusy){
            this.$store.dispatch('setStatusMsg',{values:{text:this.$t("messages.formBusy"), type:'warning'},time:3000});
          }
          if(!this.readyToSend){
            this.$store.dispatch('setStatusMsg',{values:{text:this.$t("messages.notAllrequiresFieldsSet"), type:'warning'},time:3000});
          }
          this.formBusy = false;
        }
      
      //TODO! racecondition or something conflicts between the router and the caching of the store
      //check why the timeout is nessessary
      //setTimeout(()=>this.$router.push('/check'),10);
    }
  },
  computed:{
    getChecklist: function(){
      return this.$store.state.local.checkLists[this.formId];
    },
    tree() {
      return this.makeTree(this.fields, 'form_field_id', 'parent_id', 'form_fields');
    },
    root() {
      return this.fields.filter((f) => f.parent_id === 0);
    },
    fields: function(){
      if(this.getChecklist !== undefined){
        return this.getChecklist.form_fields;
      }else{
        return []
      }
    },
    ...mapGetters([
        'getValueForCurLanguage',
        'getNextRoute',
    ])
  },
  /*mounted() {
    if(this.formId === -1){
      this.$store.dispatch('getChecklist', this.vehicleID);
      if(this.additionalForm !== undefined){
        //this.$store.dispatch('getChecklist', this.additionalForm);
      }
    }
  }*/
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.form{
  background-color:$bgColorLight;
  min-height: 100vh;
  padding-bottom: 40px;
  .button-container{
    width:100%;
    display:flex;
    justify-content: space-between;
  }
  .button-container .disabled, .button-container .busy{
      opacity: .5;
  }
  .button-container .busy{
      pointer-events: none;
  }
  .confirmButton img{
      width:38px;
  }
  .form-wrap{
    width:90vw;
    margin:auto auto 60px auto;
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/styles.scss";
.form{
  .backButton .button-wrap, .confirmButton{
    background-color: white;
  }
}
/** CSS loader **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 45px;
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  margin-left:5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $fontColorDark;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>