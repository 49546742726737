<template>
  <div class="personal-data">
    <appheader 
      :headline='$t("personalData.title")'
    ></appheader>
    <hint>{{$t("personalData.hint")}}</hint>
    <formWrap :title='$t("personalData.title")'>
      <textField
        v-for='metafield in metafields'
        :key='metafield.user_meta_id'
        :fix='true'
        formId='personalData' 
        :fieldId='metafield.meta_label !== undefined && Array.isArray(metafield.meta_label) && getValueForCurLanguage(metafield.meta_label).value !== undefined ? getValueForCurLanguage(metafield.meta_label).value : metafield.meta_key'
        :value='metafield.meta_value'
        :label='metafield.meta_label !== undefined && Array.isArray(metafield.meta_label) && getValueForCurLanguage(metafield.meta_label).value !== undefined  ? getValueForCurLanguage(metafield.meta_label).value : metafield.meta_key'>
      </textField>
      
      <!--<textField :fix='true' formId='personalData' fieldId='username' :contents="[{value:fullName, label:'Vor- und Zuname'}]"></textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='street'
        :contents="[
          {value:this.$store.state.user.street, label:'Straße'},
          {value:this.$store.state.user.nr, label:'Nr.'}
        ]">
      </textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='city'
        :contents="[
          {value:this.$store.state.user.city, label:'Ort'},
          {value:this.$store.state.user.plz, label:'PLZ'}
        ]">
      </textField>
      <textField
        :fix='true'
        formId='personalData' fieldId='email'
        :contents="[
          {value:this.$store.state.user.email, label:'E-Mail'},
        ]">
      </textField>-->
      <formButton destination='./' :imgSrc='getImgUrl("Nav_back.svg")'></formButton>
    </formWrap>
    <router-view />
    <appfooter></appfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import FormWrapper from "@/components/FormWrapper.vue";
//import TextFieldWithLable from "@/components/FormFields/TextFieldWithLable.vue";
import TextFieldWithLable from "@/components/FormFields/TextAreaWithLable.vue";
import Button from "@/components/FormFields/RouterButton.vue";
import { mapGetters } from 'vuex'

export default {
    components: {
        'formWrap' :FormWrapper,
        'textField':TextFieldWithLable,
        'formButton':Button,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        }
    },
    computed:{
        metafields: function(){
            return this.$store.state.user.meta.groups[0].fields;
        },
        fullName:function(){
            if(this.$store.state.user.firstName || this.$store.state.user.lastName){
                return this.$store.state.user.firstName + " " + this.$store.state.user.lastName
            }
            return this.$store.state.user.username;
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    beforeCreate() {
        console.log("beforeCreate");
        this.$store.dispatch('getProfileData');
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.personal-data{
    background-color:$bgColorLight;
    min-height: 100vh;
}
</style>