<template>
    <div class="check-wrap">
        <div class="check-lable-wrap">
            <label>{{label}}</label>
            <p class="desc">{{description}}</p>
        </div>
        <div 
            class="check-options-wrap"
        >   
        <!-- TODO! what to use as unique name for the check group? -->
            <div v-for='(option, index) in options' :key='index' class="check-option">
                <input :id='index+1' type="checkbox" v-bind:value='option.value !== null ? option.value : index+1' v-model="value" :name='"check"+fieldId'/>
                <span class="checkmark"></span>
                <label>{{option.label[languageCode].value}}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            optionResults:[],
        }
    },
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        label:String,
        propValue:String,
        description:String,
        options:Array,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        //bring set value to parent component - decoupling
        setValue(){
            this.$store.commit("setFieldResult",{'formId':this.formId,'fieldId':this.fieldId,'value':this.optionResults, 'parentId':this.parentId, type:'checkbox'})
        },
    },
    computed:{
        value:{
            set(val){
                this.$store.commit("setFieldResult",{'formId':this.formId,'fieldId':this.fieldId,'value':val, 'parentId':this.parentId, type:'checkbox'})
            },
            get(){
                return this.$store.getters.getFieldResult({fieldId:this.fieldId,parentId:this.prentId,formId:this.formId});
            }
        },
        languageCode: function(){
            return this.$store.state.user.languageCode;
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.check-wrap{
    width: 100%;
    margin: 0 auto;
    border-radius: 3px;
    background-color: $bgColorLightLight;
    padding:8px 0px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .check-options-wrap{
        display:flex;
        flex-direction: column;
        text-align: left;
        .check-option{
            line-height: 3;
        }
    }
    .desc{
        width: 100%;
        padding:0;
        margin:0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>