<!-- shows a single socumentation entry with a trafficlight -->
<template>
    <div class="docu-entry">
        <div class="docu-entry-wrap" v-on:click='showDocuEntryDetails()'>
            <div class="docu-light-wrap">
                <trafficlight :status='getStatus()'></trafficlight>
            </div>
            <div class="docu-content-wrap">
                <p class="docu-content docu-time">{{startTime}}</p>
                <p class="docu-content">{{type}}</p>
            </div>
        </div>
        <transition name="animate-slide-down">
            <div class="dropdown-all-values-wrap" v-if="detailActive" v-on:click="hideDocuEntryDetails()">
                <div class="dropdown-title">
                    {{$t("documentation.tourInfo")}}:
                </div>
                <div class="info">{{$t("documentation.startTime")}}: <span>{{startTime}}</span></div>
                <div class="info">{{$t("documentation.vehicle")}}: <span>{{vehicle.license_plate}}</span></div>
                <div class="info" v-if='secondaryVehicles[0] !== undefined'>
                    {{$t("documentation.trailer")}}: <span>{{secondaryVehicles[0].license_plate}}</span>
                </div>
                <div class="dropdown-title">
                    {{$t("documentation.forms")}}:
                </div>
                <div v-if='!Array.isArray(submissions) || submissions.length <= 0'>
                    {{$t("documentation.noForms")}}
                </div>
                <div class="form-wrap" v-else v-for='(sub,index) in submissions' :key='index'>
                    <router-link class="form-link" :to='"/docu/"+index'>
                        <trafficlight :status='getStatus(sub.error_status)'></trafficlight>
                        <div class="form-text">{{getValueForCurLanguage(sub.form.form_title).value}}</div>
                    </router-link>
                </div>
                <div class="dropdown-title">{{$t("documentation.timers")}}:</div>
                <div v-if='!Array.isArray(timers) || timers.length <= 0'>
                    {{$t("documentation.noTimers")}}:
                </div>
                <timeEntryList v-else :timers='timers'></timeEntryList>
            </div>
        </transition>
    </div>
</template>

<script>

import TrafficLight from "@/components/TrafficLight.vue";
import TimeEntryList from "@/components/TimeEntryList.vue";
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            detailActive: false,
        }
    },
    props:{
        startTime:String,
        type:String,
        status:Number,
        index:Number,
        tourId:Number,
        page:Number,
    },
    computed:{
        submissions(){
            return this.$store.state.local.documentation.tourDetails.submissions;
        },
        vehicle:function(){
            return this.$store.state.local.documentation.tourDetails.vehicle;
        },
        secondaryVehicles:function(){
            return this.$store.state.local.documentation.tourDetails.secondaryVehicles;
        },
        timers:function(){
            return this.$store.state.local.documentation.tourDetails.timers;
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    methods: {
        showDocuEntryDetails(){
            this.detailActive = true;
            //console.log("navigate to form:",this.tourId)
            //liefert momentan das gleiche was in der touren list kommt
            this.$store.dispatch('getTour', this.tourId)
        },
        hideDocuEntryDetails(){
            this.detailActive = false;
            //console.log("hide docu detail");
        },
        getStatus(error_status){
            //return 2;
            var status = -1;
            if(error_status !== undefined){
                error_status.forEach(element => {
                    if(element.count > 0 && Number(element.severity) > status){
                        status = Number(element.severity);
                    }
                });
            }else{
                if(this.$store.state.local.documentation.finishedChecks[this.page][this.index].status >= 0){
                    status = Number(this.$store.state.local.documentation.finishedChecks[this.page][this.index].status);
                }
            }
            status++;
            if(status === 0){
                status = 1;
            }
            return status;
        },
    },
    components: {
        'trafficlight':TrafficLight,
        'timeEntryList':TimeEntryList,
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.docu-entry-wrap{
    display:flex;
    margin:10px 5px;
    padding:10px;
    border-bottom:1px solid rgba(0,0,0,.2);
    text-align:left;
    cursor: pointer;
    .docu-light-wrap{
        width:25%;
    }
    .docu-time{
        font-size:1.2em;
    }
    .docu-content{
        margin:0;
    }
}
.dropdown-all-values-wrap{
    background-color:$bgColorLightLight;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding:20px;
    overflow-y: scroll;
    .dropdown-title{
        color:$fontColorHighlighted;
        font-weight: bold;
        margin:20px 0 10px 0;
        padding:5px;
        text-transform: uppercase;
        text-align: left;
    }
    .light-wrap{
        max-width: 20px;
        max-height: 50px;
        margin:10px;
        .light{
            height:12px !important;
        }
    }
    .form-link{
        display: flex;
        text-decoration: none;
        .form-text{
            padding: 20px;
            font-size: 1.2rem;
            color:$fontColorDark;
        }
    }
    .info{
        width: 100%;
        text-align: left;
        padding: 5px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
    ul{
        list-style: none;
        margin:0;
        padding:0;
        li{
            display: flex;
            flex-direction: column;
            margin:10px;
            padding:10px;
            border-radius:5px;
            border:1px solid rgba(0,0,0,0.4);
            cursor:pointer;
        }
    }
    img{
        width: 200px;
    }
    .timeEntryList{
        padding: 20px;
    }
}
.button-container{
    padding: 0 20px 20px 20px;
}
.animate-slide-down-enter{
    margin-top:-100vh;
}
.animate-slide-down-enter-active{
    transition: margin-top .5s;
}
.animate-slide-down-enter-to{
    margin-top:0;
}
.animate-slide-down-leave-active{
    margin-top:-100vh;
    transition: margin-top .5s;
}
</style>
<style lang="scss">
    .dropdown-all-values-wrap{
        .light-wrap{
            .light{
                height:12px !important;
            }
        }
    }
</style>