<template>
    <div class="bool-wrap" ref="boolRef">
        <div class="bool-lable-wrap">
            <label>{{label}}</label>
            <p class="desc">{{description}}</p>
        </div>
        <div 
            class="bool-buttons-wrap"
            :class="[
                value.toString()
            ]"
        >
            <formButton class="decline" v-on:click.native="setValue(false)" :imgSrc="getImgUrl('Nav_decline.svg')"></formButton>
            <formButton class="bool" v-on:click.native="setValue(true)" :imgSrc="getImgUrl('Nav_check.svg')"></formButton>
        </div>
    </div>
</template>

<script>
import Button from "@/components/FormFields/Button.vue";
export default {
    components:{
        'formButton':Button,
    },
    props:{
        fieldId:Number,
        parentId:Number,
        formId:Number,
        label:String,
        required:Boolean,
        propValue:String,
        description:String,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        setValue(value){
            this.$store.commit("setFieldResult",{'formId':this.formId,'fieldId':this.fieldId,'value':value, 'parentId':this.parentId,type:'boolean'})
            
            //set options in optionsPopup if value = false and otions exist
            //console.log("selectedOption before if: ",this.selectedOption);
            //console.log("this.options.Length: ",this.options.length);
            if(this.selectedOption >= 0 && this.selectedOption < this.options.length){
                //console.log("selectedOption exists in options");
                //console.log("selectedOption in if: ",this.selectedOption);
                //console.log("options type: ",this.options[this.selectedOption].type);
                if(this.options[this.selectedOption].type !== 'option'){
                    //console.log("NOT the option (text,file, ...) was selected");
                    this.$store.commit("setOptionsPopup",{'selectedOptionIndex':this.selectedOption, field:{'formId':this.formId, 'parentId':this.parentId, 'fieldId':this.fieldId, label:this.label, description:this.description}})
                }
            }
        },
    },
    //value for the dynamic css class
    computed:{
        value: function(){
            var value = this.$store.getters.getFieldResult({fieldId:this.fieldId,parentId:this.parentId,formId:this.formId});

            return value;
        },
        parentGroupComplet: function(){
            return this.$store.getters.getGroupState({fieldId:this.parentId,formId:this.formId})
        },
        fieldComplete: function(){
            //console.log("fieldComplete: ", this.$store.getters.getGroupState({fieldId:this.fieldId,formId:this.formId}))
            return this.$store.getters.getGroupState({fieldId:this.fieldId,formId:this.formId})
        },
        selectedOption: function(){
            if(this.$store.state.local.checkResultsNew[this.formId] !== undefined){
                if(this.$store.state.local.checkResultsNew[this.formId][this.fieldId] !== undefined){
                    return this.$store.state.local.checkResultsNew[this.formId][this.fieldId].selectedOption;
                }
            }
            return undefined;
        },
        options: function(){
            if(this.$store.state.local.checkResultsNew[this.formId] !== undefined){
                if(this.$store.state.local.checkResultsNew[this.formId][this.fieldId] !== undefined){
                    return this.$store.state.local.checkResultsNew[this.formId][this.fieldId].options;
                }
            }
            return [];
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.level2 .bool-wrap{
    margin: 0 auto;
    background-color: $bgColorLightLight;
}
.bool-wrap{
    width: 100%;
    margin: 8px auto 0 auto;
    border-radius: 10px;
    background-color: $bgColorWhite;
    padding:8px 0px;
    display:flex;
    flex-direction: column;
    color:$fontColorMedLight;
    .bool-buttons-wrap{
        display:flex;
        justify-content: space-between;
        padding:0 10px;
        .button-wrap img{
            height:30px;
            width:30px;;
        }
        .decline, .bool{
            border:1px solid white;
        }
        &.true .bool{
            background-color: $trafficLightColorGreen;
            border:1px solid black;
        }
        &.false .decline{
            background-color: $trafficLightColorRed;
            border:1px solid black;
        }
    }
    .desc{
        width: 100%;
        padding:0;
        margin:0;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>