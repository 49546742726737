<template>
    <div class="field-wrap">
        <div class="sepperator"></div>
        <!-- TODO: use methods to get the filed properties for all fieldtypes (like textField)-->
        <div class="result-wrap">
            {{getLabel}}:
            <img class="result" v-if='getValue.severity === 0' :src="getImgUrl('Nav_check.svg')"/>
            <span class="result" v-else-if='getValue.severity === 1'>-</span>
            <img class="result" v-else-if='getValue.severity === 2' :src="getImgUrl('Nav_decline.svg')"/>
        </div>
        
        <div class="fieldSymbols">
            <img v-if='hasImage === true' class='qrSymbol' :src='getImgUrl("image.svg")'/>
            <div class="textResult">{{getValue.text}}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props:{
        field: Object,
        fix: Boolean,
        formId: Number,
        highlighted: Boolean,
        submissionId: String,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
    },
    computed:{
        hasImage: function(){
            if(this.field.field_uploads != undefined){
                return true
            }
            return false;
        },
        //why all this methods?!?!?! just get the values from field
        getValue: function() {
            var valueObj = {
                severity:2,
                text:""
            };
            if(Array.isArray(this.field.field_value)){
                //console.log("this.field.field_value:",this.field.field_value)
                var result = this.field.field_value.filter(obj => {
                    return obj.selected === true;
                })
                //values can be true or false but we dont want that shown as text
                if(result[0] !== undefined){
                    if(typeof(result[0].value) === "string" && result[0].value !== "true" && result[0].value !== "false"){
                        valueObj.text = result[0].value;
                    }
                    var selected = result[0].option;
                    //console.log("field_id:",this.field.form_field_id)
                    console.log("selected:",selected)
                    if(Array.isArray(this.field.options)){
                        console.log("options:",this.field.options)
                        if(Array.isArray(this.field.options) && this.field.options[selected] !== undefined){
                            valueObj.severity = parseInt(this.field.options[selected].severity);
                            console.log("this.field.options[selected].severity:",this.field.options[selected].severity)
                            /*uncomment if we want lables shown in the docu instead of values
                            if(this.field.field_type?.type !== "boolean"){
                                valueObj.text = this.getValueForCurLanguage(this.field.options[selected].label).value;
                            }*/
                        }
                    }
                }
            }else if(typeof this.field.field_value === 'string' || this.field.field_value instanceof String){
                valueObj.text = this.field.field_value;
            }
            else{
                if(this.field.field_value === true){
                    valueObj.severity = 0;
                }else{
                    valueObj.severity = 2;
                }
            }
            return valueObj;
        },
        getLabel: function() {
            return this.getValueForCurLanguage(this.field.field_label).value;
        },
        getPlaceholder: function() {
            return this.getValueForCurLanguage(this.field.field_placeholder).value;
        },
        getDescription: function() {
            return this.getValueForCurLanguage(this.field.field_description).value;
        },
        getRequired: function() {
            if(this.field.required){
                return this.field.required
            }else{
                return false
            }
        },
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    watch: { 
        /*locked: function(newVal) { // watch it
            if(newVal == "unlocked"){
                
            }
        }*/
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
    .field-wrap{
        margin: 10px 10px;
        padding:10px 15px;
        background-color: $bgColorLightLight;
        border-radius: 3px;

        position: relative;
        .result-wrap{
            display: flex;
            justify-content: space-between;
        }
        .textResult{
            width: 100%;
            text-align: right;
        }
    }
    .fieldSymbols{
        text-align: right;
        display: flex;
    }
    .level0{
        
    }

    .level1{
        margin: 5px 0px;   
    }
    .level2{
        margin:5px;
        .sepperator{
            border-bottom:1px solid rgba(0,0,0,.1);
            width: 100%;
            margin:auto auto 10px auto;
        }
    }
    .result{
        width:20px;
        text-align: right;
        align-self: flex-end;
    }
</style>