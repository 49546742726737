<template>
  <div class="defects">
    <appheader
      :headline='$t("navigation.defect")'
    ></appheader>
    
    <MyForm 
      :formId='this.$store.state.local.curCheck.defectsId.formId' 
      :formBaseId='this.$store.state.local.curCheck.defectsId.baseId' 
      :readyToSend='formReadyToSend'
      :nextRoute='getNextRoute("defects")'>
        <div v-if='sentDefectsList.length > 0' class="sentDefectsList">
            {{$t("tour.defects.existingDefects")}}
            <div v-for='(defect,index) in sentDefectsList' class="sentDefect" :key="index">
                <div v-for='(field,ind) in defect' class="sentDefectField" :key="ind">
                    <div class="defectLabel">{{getValueForCurLanguage(field.label).value}}:</div><div class="defectValue">{{field.value}}</div>
                </div>
            </div>
        </div>
    </MyForm>
    <appfooter></appfooter>
  </div>
</template>

<script>
import MyForm from '@/components/Form.vue';
import { mapGetters } from 'vuex'

export default {
  components: {
    'MyForm': MyForm,
  },
  computed:{
    formReadyToSend: function(){
      var status = this.$store.getters.getFormFieldStatus(this.$store.state.local.curCheck.defectsId.formId);
      if(status == "ok" || status == "failed"){
        return true;
      }else{
        return false;
      }
    },
    sentDefectsList: function(){
        return this.$store.state.local.sentDefects;
    },
    ...mapGetters([
        'getNextRoute',
        'getValueForCurLanguage',
    ])
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.sentDefectsList{
    width: 100%;
    padding: 20px;
    margin: 10px auto;
    border-radius: 10px;
    background-color: white;
    text-align: left;
    .sentDefect{
        background-color:$bgColorLightLight;
        border-radius: 3px;
        margin:10px 0;
        padding:10px;
    }
    .sentDefectField{
        margin:3px 0;
        display: flex;
        justify-content: space-between;
    }
    .defectValue{
        text-align: right;
    }
}
</style>