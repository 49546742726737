<template>
  <div class="Check">
    <appheader 
      headline="Tour"
      v-bind:rowOne='{
        userName:fullName,
        company: this.$store.state.user.company
      }'
      v-bind:rowTwo='{
        colOne:{
          head:$t("header.tourStarted1"),
          content: noVehicleSet === false ? this.$store.getters.driveCheckStartDate : "/",
          foot:$t("header.tourStarted2"),
        },
        colTwo:{
          head:$t("header.licensePlate"),
          content:this.$store.getters.currVehiclePlate,
          foot:$t("header.vehicle")
        },
        colThree:{
          head:$t("header.licensePlate"),
          content:this.$store.getters.currTrailerPlate === "KEIN HÄNGER"? "/" :this.$store.getters.currTrailerPlate,
          foot:$t("header.trailer")
        }
      }'
      v-bind:rowThree='{
        active:true,
        colOne:{
          status:this.$store.getters.getTotalStatus,
          foot:$t("header.totalStatus")
        },
      }'
    ></appheader>
    <timerPopup
        :visible="timerPopupActive"
        :setTimerPopupState='setTimerPopupState'
        popupTextValue=''
        popupKmValue=''
        popupLabelValue='Zeiterfassung stoppen'
        curEntryType='notStarted'>
    </timerPopup>
    <!-- TODO: not only use css to blockk checklists-->
    <div class="content-wrap">
      <!--<bignavicon 
        :imgSrc='getImgUrl("view-check/Abfahrk_Fahrzeug.svg")' 
        link="/check/lkw" 
        text='Fahrzeug'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.lkwDataId)'>
      </bignavicon>-->
    <bignavicon
        v-if='this.$store.state.local.curCheck.checkListId[0].formId > -1'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Abfahrk_Fahrzeugkontrolle.svg")' 
        link="/check/checklist" 
        :text='$t("navigation.checklist")'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.checkListId[0].formId)'>
    </bignavicon>
    <bignavicon
        v-if='this.$store.state.local.curCheck.defectsId.formId > -1'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Abfahrk_Maengel.svg")'
        link="/check/defects"
        :text='$t("navigation.defect")'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.defectsId.formId)'>
    </bignavicon>
    <bignavicon
        v-if='isSupported("toDo") && this.$store.state.local.curCheck.toDoId.formId > -1'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Abfahrk_ToDo.svg")' 
        link="/check/todo" 
        :text='$t("navigation.todos")'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.toDoId.formId)'>
    </bignavicon>
    <bignavicon
        v-if='isSupported("time")'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Nachtrag.svg")' 
        link="/check/time" 
        :text='$t("navigation.report")'
        :status='this.$store.getters.getFormStatus("time")'>
    </bignavicon>
    <bignavicon
        v-if='(this.$store.state.local.nachtragTimer.currentlyTracked === "stop" || this.$store.state.local.nachtragTimer.currentlyTracked === "none")  && this.$store.state.local.curCheck.defectsId.formId > -1'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Abschluss.svg")'
        link="/check/end"
        :text='$t("navigation.end")'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.endId.formId)'>
    </bignavicon>
    <bignaviconNoRouter 
        v-if='(this.$store.state.local.nachtragTimer.currentlyTracked !== "stop" && this.$store.state.local.nachtragTimer.currentlyTracked !== "none") && this.$store.state.local.curCheck.defectsId.formId > -1'
        :class="{'disabled':noVehicleSet}"
        :imgSrc='getImgUrl("view-check/Abschluss.svg")'
        :text='$t("navigation.end")'
        :status='this.$store.getters.getFormStatus(this.$store.state.local.curCheck.endId.formId)'
        v-on:click.native='clickEndcontroll'>
    </bignaviconNoRouter>
        <div class="button-container">
            <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
            <formButton class="confirmButton" v-if='this.$store.getters.getTotalStatus !== 2' :imgSrc='getImgUrl("Nav_check.svg")' v-on:click.native='clickFinishCurCheck'></formButton>
        </div>
    </div>
    <confirmpopup :active='this.confirmActive' msg='Die aktuelle Fahrt abschließen?' :confirmed='this.confirm' :declined='this.decline'></confirmpopup>
    <appfooter></appfooter>
  </div>
</template>

<script>
// @ is an alias to /src
/*import AppHeader from "@/components/Header.vue";
import BigNavIcon from "@/components/BigNavIcon.vue";*/
import BigNavIconNoRouter from "@/components/BigNavIconNoRouter.vue"
import TimerPopup from "@/components/Popups/TimerPopup.vue"
export default {
  components: {
    'bignaviconNoRouter' : BigNavIconNoRouter,
    'timerPopup' : TimerPopup,
  },
  data(){
      return{
          confirmActive: false,
          timerPopupActive: false,
      }
  },
  methods:{
    isSupported(feature){
        return this.$store.getters.isFeatureSupportet(feature);
    },
    getImgUrl(img) {
      return require('@/assets/'+img);
    },
    clickFinishCurCheck(){
        this.confirmActive=true;
    },
    decline:function(){
        this.confirmActive = false; 
    },
    confirm:function(){
        this.$store.dispatch('endTour');
        this.$router.push('/');
        //this.$store.commit('finishCurCheck');
        this.confirmActive = false; 
    },
    clickEndcontroll:function(){
       console.log("endcontroll clicked");
       this.timerPopupActive = true;
    },
    setTimerPopupState(state){
        this.timerPopupActive = state;
    }
  },
  computed:{
    noVehicleSet:function(){
      if(this.$store.state.local.curCheck.chosenTruck >= 0){
        return false;
      }
      return true;
    },
    fullName:function(){
      if(this.$store.state.user.firstName || this.$store.state.user.lastName){
        return this.$store.state.user.firstName + " " + this.$store.state.user.lastName
      }
      return this.$store.state.user.username;
    }
  },
  beforeMount:function(){
    //set start date of check when check is opend and the date is not set allready
    this.$store.commit('setCurCheckStartTime');
  },
};
</script>

<style lang='scss'>
.Check{
  .content-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:5%;
    margin-bottom: 50px;
  }
  .button-container{
      width:100%;
      display:flex;
      justify-content: space-between;
    }
  .disabled {
      opacity: 0.5;
      pointer-events: none;
  }
}
</style>