<template>
  <div class="end">
    <appheader 
      :headline='$t("navigation.end")'
    ></appheader>
    <MyForm 
      :formId='this.$store.state.local.curCheck.endId.formId'
      :formBaseId='this.$store.state.local.curCheck.endId.baseId'
      :readyToSend='formReadyToSend'
      nextRoute='/check'>
    </MyForm>
    <appfooter></appfooter>
  </div>
</template>

<script>
import MyForm from '@/components/Form.vue';

export default {
  components: {
    'MyForm': MyForm,
  },
  computed:{
    formReadyToSend: function(){
      var status = this.$store.getters.getFormFieldStatus(this.$store.state.local.curCheck.endId.formId);
      if(status == "ok" || status == "failed"){
        return true;
      }else{
        return false;
      }
    }
  },
};
</script>
