<template>
    <div v-if="option.type !== 'option' && active" class="optionfield-wrap">
        <div class="fieldDescription">
            {{field.description}}
        </div>
        <textarea
            class="textarea"
            v-if="option.type === 'text' || option.type === 'file'"
            type="text"
            :placeholder="getValueForCurLanguage(option.placeholder).value"
            v-model='value'
            @blur="setValue"/>
        <div class="sepperator"></div>
        <label>{{getValueForCurLanguage(option.label).value}}</label>
        <!-- <input class="fileInput" v-if="option.type === 'file'" name="datei" type="file" @change="previewFiles" accept="image/*" capture="environment">-->
        <ImageUploader
            v-if="option.type === 'file'"
            :preview="false"
            :className="['fileinput', { 'fileinput--loaded': prevImgExists }]"
            capture="environment"
            :debug="0"
            doNotResize="gif"
            outputFormat="verbose"
            accept="image/*"
            @input="previewFiles"
            :maxWidth="1024"
        >
        <label for="fileInput" slot="upload-label">
            <div class="upload-caption">
                Bild Hochladen
            </div>
        </label>
        </ImageUploader>
        <div v-if="option.type === 'file'" class="lightboxBackground" :class='{"active":zoomed >= 0}' @click='zoomImg()'></div>
        <div v-if="option.type === 'file'" class="lightboxControlls" :class='{"active":zoomed >= 0}' @click='zoomImg()'>
            <img v-if='prevImgExists' class="imgnav left" @click.stop='prevImg' :src='getImgUrl("Nav_back.svg")'/>
            <img v-if='nextImgExists' class="imgnav right" @click.stop='nextImg' :src='getImgUrl("Nav_forwar.svg")'/>
            <img class="deleteIcon" @click.stop='deleteImg' :src='getImgUrl("delete_black_24dp.svg")'/>
        </div>
        <div v-if="option.type === 'file'" class="preview-wrapper">
            <div class="preview" v-for="(img,index) in fileObjects" :key='img.dateModified'>
                <img class="previewImg" :class='{"zoomed":index === zoomed}' :src='img.fileObject.dataUrl' @click='zoomImg(index)'/>
            </div>
            <!--<img v-if="fileUrl !== null && fileUrl !== undefined" class="previewImg" v-bind:src='fileUrl'/>-->
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageUploader from "vue-image-upload-resize";
export default {
    data(){
        return{
            valueToCommit: this.value,
            //fileUrlToCommit: this.fileUrl,
            file:{},
            zoomed:-1,
        }
	},
    components:{
        ImageUploader
    },
    props:{
        optionKey:Number,
        option:Object,
        field:Object,
    },
    computed:{
        prevImgExists:function(){
            if(this.zoomed > 0 && this.fileObjects[this.zoomed-1] !== undefined){
                return true;
            }
            return false;
        },
        nextImgExists:function(){
            if(this.zoomed < this.fileObjects.length -1 && this.fileObjects[this.zoomed+1] !== undefined){
                return true;
            }
            return false;
        },
        value:{
            get () {
                var val = "";
                if(this.$store.state.local.checkResultsNew[this.field.formId]){
                    if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId]){
                        if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options.length > 0){
                            val = this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options[this.optionKey].value;
                        }
                    }
                }
                //console.log("val: ",val);
                return val;
            },
            set(val){
                this.valueToCommit = val;
            }
        },
        fileUrl:function(){
            var val = "";
            if(this.$store.state.local.checkResultsNew[this.field.formId]){
                if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId]){
                    if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options.length > 0){
                        var urls = this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options[this.optionKey].fileUrl
                        val = urls;
                    }
                }
            }
            //console.log("fileurl val: ",val);
            return val;
        },
        fileObjects:function(){
            var files = "";
            if(this.$store.state.local.checkResultsNew[this.field.formId]){
                if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId]){
                    if(this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options.length > 0){
                        files = this.$store.state.local.checkResultsNew[this.field.formId][this.field.fieldId].options[this.optionKey].fileObjects.filter(function(fileObject){
                            return fileObject.status !== 'deleted';
                        });
                    }
                }
            }
            //console.log("files: ",files);
            return files;
        },
        active:function(){
            //only show the selected option!
            return this.option.selected;
        },
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getValueForCurLanguage',
        ])
    },
    methods:{
        prevImg(){
           if(this.prevImgExists){
               this.zoomed--;
           }
        },
        nextImg(){
            if(this.nextImgExists){
               this.zoomed++;
           }
        },
        getImgUrl(img) {
            return require('@/assets/'+img);
        },
        deleteImg(){
            this.zoomed;
            this.$store.commit("deleteOptionFile",{'formId':this.field.formId,'fieldId':this.field.fieldId, 'optionKey':this.optionKey, fileKey:this.zoomed})
        },
        zoomImg(index){
            if(index == this.zoomed || index === undefined){
                this.zoomed = -1;
            }else{
                this.zoomed = index;
            }
        },
        createObjectUrlFromFile(file){
            return URL.createObjectURL(file);
        },
        setValue(){
            //console.log("file in OptionFiueld.vue: ",this.file);
            this.$store.commit("setFieldOptionResult",{'formId':this.field.formId,'fieldId':this.field.fieldId, 'optionKey':this.optionKey, 'value':this.valueToCommit});
        },
        /*previewFiles(event) {
            console.log("event.target.files[0]:", event.target.files[0]);
            this.file = event.target.files[0];
            console.log("this.file: ",this.file);
            //var url = URL.createObjectURL(this.file);
            //console.log("url: ",url);
            //if(url !== undefined){
            this.$store.commit("setFieldOptionResult",{'formId':this.field.formId,'fieldId':this.field.fieldId, optionKey:this.optionKey, 'value':this.valueToCommit, 'fileObject':event.target.files[0]});
            //}
        }*/
        previewFiles(imageObject) {
            //console.log("imageObject: ",imageObject);
            this.file = imageObject;
            //console.log("this.file: ",this.file);
            this.$store.commit("setFieldOptionResult",{'formId':this.field.formId,'fieldId':this.field.fieldId, optionKey:this.optionKey, 'value':this.valueToCommit, 'fileObject':imageObject});
        }
    },
    beforeMount: function(){
        this.valueToCommit = this.option.value;
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.optionfield-wrap{
    &.active{
         height:auto;
    }
    textarea{
        width: 80%;
        height:100px;
        font-size: 3rem;
        background: transparent;
        border: none;
        padding:5px;
        font-size: 1.2rem;
        resize:none;
        outline: none;
    }
    .optionsfield-col-wrap{
        flex: 1 1 50%;
    }
    .sepperator{
        border-bottom:1px solid rgba(0,0,0,.1);
        width: 80%;
        margin:auto;
    }
    .lightboxBackground{
        display:none;
        position: fixed;
        width:100vw;
        height:100vh;
        background-color: rgba(255,255,255,1);
        left:0;
        top:0;
        z-index: 1;
        &.active{
            display: block;
        }
    }
    .upload-caption{
        border:1px solid black;
        border-radius: 3px;
        padding: 10px;
    }
    .preview-wrapper{
        display: flex;
        justify-content: space-evenly;
        .preview{
            display: block;
            flex-basis:33%;
            margin: 10px;
        }
        .previewImg{
            display: block;
            max-width: 100%;
            cursor: pointer;
        }
        .zoomed{
            position: absolute;
            left:0;
            top:30%;
            width:100%;
            z-index: 101;
        }
        .base64view{
            max-width: 300px;
            overflow: hidden;
            float:left;
        }
    }
    .fix{
        padding:5px;
    }
    label, .fieldDescription{
        padding:5px;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
    ::v-deep #fileInput{
        display:none;
    } 
    .fileInput{
        padding:15px 10px;
        font-size: 1rem;
        color: $fontColorMedLight;
        max-width: 100%;
    }
    .lightboxControlls{
        position: fixed;
        z-index: 102;
        display: none;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        &.active{
            display: block;
        }
    }
    .imgnav{
        position: absolute;
        width: 30px;
        cursor: pointer;
        top: 50vh;
        padding:5px;
        &.left{
            left:10px;
        }
        &.right{
            right:10px;
        }
    }
    .deleteIcon{
        position: absolute;
        bottom: 10px;
        left: 10px;
        padding:5px;
        cursor: pointer;
    }
}

/** CSS loader **/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  float:left
}
.lds-ellipsis div {
  position: absolute;
  top: 12px;
  margin-left:5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $fontColorDark;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>