<template>
    <div class="textfield-wrap">
        <div class="textfield-col-wrap">
        <textarea v-if="!fix" type="text" :placeholder="placeholder ? placeholder : ''"  v-model='initialValues' @blur="setValue"/>
        <span class="fix" v-else>{{value}}</span>
        <div class="sepperator"></div>
        <label>{{label}}</label>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
            values: this.initialValues,
        }
	},
    props:{
        fix:Boolean,
        fieldId:String,
        formId:String,
        parentId:Number,
        required:Boolean,
        value:String,
        label:String,
        placeholder:String,
    },
    computed:{
        initialValues:{
            get () {
                let vals = ''
                if(this.$store.state.local.checkResultsNew[this.formId]){
                    if(this.$store.state.local.checkResultsNew[this.formId][this.fieldId]){
                        vals =  this.$store.state.local.checkResultsNew[this.formId][this.fieldId].value;
                    }
                }
                //console.log("initial values: ",vals);
                return vals;
            },
            set(val){
                this.values = val;
            }
        }
    },
    methods:{
        setValue(){
            this.$store.commit("setFieldResult",{'formId':this.formId,'fieldId':this.fieldId,'value':this.values, 'parentId':this.parentId, type:'text'})
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/styles.scss";
.textfield-wrap{
    width: 100%;
    margin: 10px auto;
    border-radius: 3px;
    background-color: $bgColorLightLight;
    padding:8px 3px;
    display:flex;
    flex-wrap: wrap;
    textarea{
        width: 90%;
        height:100px;
        font-size: 3rem;
        background: transparent;
        border: none;
        padding:5px;
        font-size: 1.2rem;
        resize:none;
        outline: none;
    }
    .textfield-col-wrap{
        flex: 1 1 50%;
    }
    .sepperator{
        border-bottom:1px solid rgba(0,0,0,.1);
        width: 80%;
        margin:auto;
    }
    .fix{
        padding:5px;
    }
    label{
        padding:5px;
        font-size: .7rem;
        font-weight: bold;
        color: $fontColorMedLight;
    }
}
</style>