<template>
    <div class="lkw-data">
        <appheader 
            :headline='$t("navigation.checklist")'
        ></appheader>
        <hint>{{$t("tour.vehicle.hint")}}</hint>
        <formWrap :title='$t("tour.vehicle.title")'>
            <dropDownFullscreen 
                :defaultValue="this.$store.state.local.curCheck.chosenTruck"
                :label='$t("tour.vehicle.label")'
                :dropdownText='$t("tour.vehicle.pleaseSelectText")'
                :contents="this.$store.getters.truckPlates"
                mutation="setTruck"
            >
            </dropDownFullscreen>
            <dropDownFullscreen v-if='lwkSet && !noTrailers' 
                :defaultValue="this.$store.state.local.curCheck.chosenTrailer"
                :label='$t("tour.vehicle.trailerLabel")'
                :dropdownText='$t("tour.vehicle.trailerSelectText")'
                :contents="this.$store.getters.trailerPlates"
                mutation="setTrailer"
            >
            </dropDownFullscreen>
            <!--<dropDownFullscreen
                :defaultValue="this.$store.state.local.curCheck.vehicleType" 
                label="Fahrzeugtyp"
                dropdownText="Bitte wähle den Fahrzeugtyp"
                :contents="this.$store.state.vehicles.vehicleTypes"
                mutation="setType"
            >
            </dropDownFullscreen>-->
            <div class="button-container">
                <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
                <!--<routerButton destination='/check' v-if='trailerSet && lwkSet' :imgSrc='getImgUrl("Nav_check.svg")' v-on:click.native='confirmForm'></routerButton>-->
                <!--<formButton v-if='tourActive' :imgSrc='getImgUrl("Nav_decline.svg")' v-on:click.native='abortTour'></formButton>-->
                <formButton v-if='lwkSet && (trailerSet || noTrailers)' :imgSrc='getImgUrl("Nav_check.svg")' v-on:click.native='confirmForm'></formButton>
            </div>
        </formWrap>
        <router-view />
        <appfooter></appfooter>
        <confirmpopup :active='this.confirmActive' msg='Möchtest du die aktuelle Tour wirklich abbrechen? Alle nicht gesendeten Eingaben gehen dabei verloren!' :confirmed='this.confirm' :declined='this.decline'></confirmpopup>
    </div>
</template>

<script>
// @ is an alias to /src
import FormWrapper from "@/components/FormWrapper.vue";
import DropDownFullscreen from "@/components/FormFields/DropDownFullscreen.vue";
import RouterButton from "@/components/FormFields/RouterButton.vue";
import FormButton from "@/components/FormFields/Button.vue";

export default {
    data(){
        return{
            confirmActive: false,
        }
    },
    components: {
    'formWrap' :FormWrapper,
    'routerButton':RouterButton,
    'formButton':FormButton,
    'dropDownFullscreen':DropDownFullscreen,
    },
    methods:{
        getImgUrl(img) {
            return require('@/assets/'+img)
        },
        confirmForm(){
            this.$store.dispatch('getChecklists', this.$store.state.local.curCheck.chosenTruck);
            /*if(this.additionalForm !== undefined){
                this.$store.dispatch('getChecklist', this.additionalForm);
            }*/
            this.$store.commit('setFormStatusSend',{formId:this.$store.state.local.curCheck.lkwDataId,value:'sent'});
            var secondary = [];
            if(this.$store.state.local.curCheck.chosenTrailer > 0){
                secondary = [this.$store.state.local.curCheck.chosenTrailer]
            }
            this.$store.dispatch('startTour', {truckId:this.$store.state.local.curCheck.chosenTruck, secondaryIds:secondary}).then(()=>{
                //TODO! racecondition or something conflicts between the router and the caching of the store
                //check why the timeout is nessessary
                setTimeout(()=>this.$router.push('/check/checklist'),10);
            })
        },
        abortTour(){
            this.confirmActive = true;    
        },
        decline:function(){
            this.confirmActive = false; 
        },
        confirm: function(){
            this.$store.commit('finishCurCheck');
            this.confirmActive = false;
            this.$store.dispatch('setStatusMsg',{values:{text:'Tour abgebrochen.', type:'success'},time:3000});
        },
    },
    computed:{
        noTrailers(){
            if(this.$store.state.vehiclesAPI.vehicles[this.$store.state.local.curCheck.chosenTruck].can_have_trailer === 0){
                return true;
            }else{
                //check if there are more than 1 Trailers (the 1 is "Kein Hänger")
                if(Object.keys(this.$store.state.vehiclesAPI.trailers).length > 1){
                    return false;
                }else{
                    return true;
                }
            }
        },
        lwkSet(){
            return this.$store.state.local.curCheck.chosenTruck >= 0;
        },
        trailerSet(){
            return this.$store.state.local.curCheck.chosenTrailer >= 0;
        },
        tourActive(){
            return this.$store.state.local.curCheck.curTourId >= 0;
        }
    },
    mounted(){
        this.$store.dispatch('getVehiclelist');
        this.$store.dispatch('getTrailerlist');
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/styles.scss";
.lkw-data{
    background-color:$bgColorLight;
    min-height: 100vh;
}
.button-container{
    width:100%;
    display:flex;
    justify-content: space-between;
}
.backButton .button-wrap, .confirmButton{
    background-color: white;
}
.confirmButton img{
    width:38px;
}
</style>