<!-- Shows the overview of the documentation page with all the tours -->
<template>
  <div class="docu">
    <appheader 
      :headline='$t("navigation.documentation")'
    ></appheader>
    <docuentrylist :page='activePage'></docuentrylist>
    <div class="button-container">
      <routerButton destination='/' :imgSrc='getImgUrl("Nav_back.svg")'></routerButton>
    </div>
    <appfooter></appfooter>
  </div>
</template>

<script>
//import DocuEntry from "@/components/DocuEntry.vue";
import DocuEntryList from "@/components/DocuEntryList.vue";


export default {
  data(){
      return{
          detailData: {}
      }
  },
  components:{
    'docuentrylist':DocuEntryList
  },
  computed:{
    activePage: function(){
      return this.$store.state.local.documentation.activePage;
    }
  },
  methods:{
    
    getImgUrl(img) {
      return require('@/assets/'+img);
    },
  },
  beforeMount(){
    this.$store.dispatch('getTourList',1);
  },

};

</script>

<style scoped lang='scss'>
@import "@/assets/scss/styles.scss";
.docu{
    margin-bottom: 50px;
}
.button-container{
  padding:0 20px;
}
</style>
